import React, {useState, useEffect, useRef} from 'react';
import ProgressInputWalletAddress from '../Components/progressInputWalletAddress.js';
import four from '../images/4.png';
import five from '../images/5.png';
import yellowline from '../images/yellow line.png';
import verticalline from '../images/vertical line.png';
import floatingicon from '../images/Floating Icon.png';
import fixedicon from '../images/Fixed Icon.png';
import {toast, Bounce } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import copyicon from "../images/Copy Icon.png";
import success from "../images/Success Illustration.png";
import txlink from "../images/Arrow Button.png";
import toicon from "../images/To Icon.png";
import star_gold from '../images/Bullet Point Icon Gold.png';
import trustpilot_logo from '../images/Trustpilot Logo.png';
import Draggable from "react-draggable";


const Success=()=>{

    const navigate = useNavigate();

    const [deposit_address_length, setDeposit_Address_Length]=useState();
    const [recipient_address_length, setRecipient_Address_Length]=useState();
    const [tx_hash_length, setTx_Hash_Length]=useState();
    const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
    const [dragPositionRecipientWalletAddress, setDragPositionRecipientWalletAddress] = useState({ x: 0, y: 0 });
    const [dragDepositAddress, setDragDepositAddress] = useState({ x: 0, y: 0 });


    const txHashRef = useRef(null);
    const recipientWalletRef = useRef(null);
    const depositWalletRef = useRef(null);

    const handleDrag = (e, ui, setPosition, ref) => {
        const parentWidth = ref.current.parentElement.offsetWidth;
        const textWidth = ref.current.offsetWidth;
        const maxDragX = parentWidth - textWidth;

        setPosition((prevPosition) => {
            const newX = prevPosition.x + ui.deltaX;
            return {
                x: Math.min(0, Math.max(newX, maxDragX)),  // Clamping the x position
                y: 0, // We are only moving in the x direction
            };
        });
    };

    const handleDragTxHash = (e, ui) => handleDrag(e, ui, setDragPosition, txHashRef);
    const handleDragRecipientWallet = (e, ui) => handleDrag(e, ui, setDragPositionRecipientWalletAddress, recipientWalletRef);
    const handleDragDepositWallet = (e, ui) => handleDrag(e, ui, setDragDepositAddress, depositWalletRef);

    useEffect(()=>{

        setDeposit_Address_Length(sessionStorage.getItem("depositaddress").length);
        setRecipient_Address_Length(sessionStorage.getItem("recipientaddress").length);
        setTx_Hash_Length(sessionStorage.getItem("txhash").length);
    },[]);

    function copyOrderTacker(){
            navigator.clipboard.writeText(sessionStorage.getItem("ordertrackerid"))
            .then(() => {
            toast.success('Order Tracker ID copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          })
          .catch((err) => {
            toast.error('Failed copying Order Tracker ID!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          });
    }

        function copyDepositAddress(){
                navigator.clipboard.writeText(sessionStorage.getItem("depositaddress"))
                .then(() => {
                toast.success('Deposit Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Deposit Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
            
        }

        function copyRecipientAddress(){
                navigator.clipboard.writeText(sessionStorage.getItem("recipientaddress"))
                .then(() => {
                toast.success('Recipient Address copied!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              })
              .catch((err) => {
                toast.error('Failed copying Recipient Address!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
              });
        }

        function copyTxHash(){
            navigator.clipboard.writeText(sessionStorage.getItem("txhash"))
            .then(() => {
            toast.success('Transaction Hash copied!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          })
          .catch((err) => {
            toast.error('Failed copying Transaction Hash!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
          });
    }

    return(
    <div className='success-pg'>
    <ProgressInputWalletAddress progress={"success"}></ProgressInputWalletAddress>
    <div className='success-img-div'><img src={success}></img></div>
    <div className='success-text-heading'>Exchange Successful!</div>
    <div className='success-btns'>
        <button type="button" class="btn" onClick={()=>{navigate("/offers")}}>Create New Order</button>
        <a class="btn" href='https://www.trustpilot.com/review/coinoswap.com' target='_blank'>Rate Us On <img src={trustpilot_logo}></img></a>
    </div>
    <div className='success-coin-data-div'>

        {/* Coin 1 */}
        <div>
        <div className='success-you-send-amount'>
            <div>You Send:</div><div>{sessionStorage.getItem("local_send_amount")}</div>
        </div>
            <div>
                <img src={sessionStorage.getItem("local_send_crypto_logo")}></img>
                <span>{sessionStorage.getItem("local_send_crypto_name")}</span>
            </div>
        
        </div>

        <div className='toicon'><img src={toicon}></img></div>

        {/* Coin 2 */}
        <div>
        <div className='success-you-get-amount'>
            <div>You Get:</div><div>{sessionStorage.getItem("local_get_amount")}</div>
        </div>
            <div>
                <img src={sessionStorage.getItem("local_get_crypto_logo")}></img>
                <span>{sessionStorage.getItem("local_get_crypto_name")}</span>
            </div>
        
        </div>
         
    </div>
    <div className='success-completion'>
        <div>
             <img src={star_gold}></img>
             <span>Estimated: 1 BTC ~ 20 ETH</span>
        </div>

        <div>
             <img src={star_gold}></img>
             <span>Completed Time: {sessionStorage.getItem('completionTime')}</span>
        </div>
        
    </div>

    <div className='row'>
    <div className='col-6' style={{paddingLeft:"30px"}}>
                <div  style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Deposit Address:</span>
                    <div className='rounded-div'><div className='deposit-address-width'><Draggable axis="x" onDrag={handleDragDepositWallet}  position={{ x: dragDepositAddress.x, y: 0 }} ><span ref={depositWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragDepositAddress.x}px)`}}>{sessionStorage.getItem("depositaddress")}
                    </span></Draggable></div></div>
                </div>
                <div className='left-round-div' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Recipient Address:</span>
                    <div className='rounded-div'><div className='recipient-address-width'><Draggable axis="x" onDrag={handleDragRecipientWallet}  position={{ x: dragPositionRecipientWalletAddress.x, y: 0 }} ><span ref={recipientWalletRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPositionRecipientWalletAddress.x}px)`}}>{sessionStorage.getItem("recipientaddress")}
                    {/* <img src={copyicon} id='thumb-cursor'  onClick={copyRecipientAddress} className='copy-icon'></img> */}
                    </span></Draggable></div></div>
                </div>

                <div className='left-round-div' style={{width:"fit-content"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Blockchain TX Hash:</span>
                    <div style={{display:"flex", alignItems:"center", marginTop:"13px"}}>
                    <div className='rounded-div-tx'>
                        <div className='txhash-width'>
                            <Draggable axis="x" onDrag={handleDragTxHash}  position={{ x: dragPosition.x, y: 0 }} ><span ref={txHashRef} style={{cursor:"grab", display: 'inline-block', transform: `translateX(${dragPosition.x}px)`}}>{sessionStorage.getItem("txhash")}</span></Draggable>
                        </div>
                        <img src={copyicon} id='thumb-cursor'  onClick={copyTxHash} className='copy-icon'></img>
                    </div>
                    <a id="thumb-cursor" href={sessionStorage.getItem('txhashlink')}  target="_blank"><img src={txlink} style={{width:"35px", height:"35px"}}></img></a>
                </div>
                </div>
                <div style={{paddingRight:"15%", marginTop:"2%"}}>
                <span style={{color:"#EF960F", fontSize:"13px"}}>* Please Note The Exchange Times Depend On How Fast The Network Confirms The Transaction And Generates A New Block On The Blockchain Network. Keep In Mind That Different Coins Have Different Confirmation Times.</span>
                </div>
        </div>
        <div className='col-6'>
            <div>
                <div style={{display:"flex"}}>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Order Tracker ID:</span>
                    <div className='rounded-div'><span>{sessionStorage.getItem("ordertrackerid")}<img src={copyicon} id='thumb-cursor' onClick={copyOrderTacker} className='copy-icon'></img></span></div>
                </div>
                <div style={{width:"100%", margin:"0px 20px"}}>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Partner:</span>
                    <div style={{marginTop:"15px", width: "240px"}}><img className='img-fluid' src={sessionStorage.getItem("local_exchange_logo")}></img></div>
                </div>
                </div>
            </div>
            <div>
            <div style={{display:"flex", marginTop:"30px"}}>
            <div className='floating-box-progress'>
                    <span className='basement-font submit-data' style={{color:"white"}}>Exchange Type:</span>
                    <div className='rounded-div'><div className='trx-type-submit'><img style={{filter:"brightness(2000%)"}} src={sessionStorage.getItem("local_exchange_type")=="Floating"?floatingicon:fixedicon}></img><span>{sessionStorage.getItem("local_exchange_type")}</span></div></div>
                </div>
                </div>
                <div style={{marginLeft:"20px", marginTop:"4%"}}><span style={{color: "#928F8C", fontSize:"13px"}}>* It Is Recommended To Retain Your Order Tracker ID Until The Completion Of The Swap. Should You Have Any Queries, Please Do Not Hesitate To Reach Out To Your Support Team.</span></div>
                <a style={{color:"#EF960F", fontSize:"13px", marginLeft:"20px"}}>suppot@coinoswap.com</a>
            </div>
        </div>
    </div>

    <div className='recipient-input-how-to'><span>How To Swap Crypto</span><span>Coinoswap Is A Non-Custodial Crypto Exchange Aggregator Providing </span><span>Convenient And Speedy Exchanges To Swap Pairs.</span></div>
    <div className='three-and-four-step'>
        <div><img src={four}></img><div><span>Step 4</span><span>Send The Required Deposit</span><span>To The Designated</span><span>Address</span></div></div>
        <div><img className='img-fluid' src={yellowline}></img><img className='img-fluid' src={verticalline} style={{display:"none"}}></img></div>
        <div><img src={five}></img><div><span>Step 5</span><span>Wait Until Your Swap</span><span>Is Successfully</span><span>Completed.</span></div></div>
    </div>
    </div>);
}

export default Success;
import React, { useEffect, useState } from 'react';
import logo from '../images/logo.png';
import language from '../images/language.png';
import england from '../images/England Flag.png';
import spain from '../images/Spain Flag.png';
import france from '../images/France Flag.png';
import germany from '../images/GER.png';
import italy from '../images/Italy Icon.png';
import portugal from '../images/Portugal Flag.png';
import swedon from '../images/SWE.png';
import china from '../images/CHI.png';
import japan from '../images/JPN.png';
import pakistan from '../images/Pakistan Flag.png';
import saudia from '../images/Arabia Flag.png';
import russia from '../images/Russia Flag.png';
import turkey from '../images/Turkey Flag.png';
import mobilemenu from '../images/Menu.png';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import {toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import changenow_black from '../images/changenow.png';
import changelly_black from '../images/changelly.png';
import changehero_black from '../images/changehero.png';
import exolix_black from '../images/exolix.png';
import godex_black from '../images/godex.png';
import letsexchange_black from '../images/letsexchange.png';
import simpleswap_black from '../images/simpleswap.png';
import stealthex_black from '../images/stealthex.png';
 

const Navbar = () => {

  const navigate = useNavigate();

  //Capture route
  const location = useLocation();

  // You can use props here, for example:
  const[lang, setLanguage]=useState(localStorage.getItem("language"));
  const[orderid, setOrderId]=useState("");
  const [currentUrl, setCurrentUrl]=useState(location.pathname);
  const [homeclass, setHomeClass]=useState("");
  const [howitworksclass, setHowItWorksClass]=useState("");
  const [affiliateprogramclass, setAffiliateProgramClass]=useState("");
  const [faqclass, setFaqClass]=useState("");
  const [aboutusclass, setAboutUsClass]=useState("");

  function formatCustomDate(isoDateStr) {
    const date = new Date(isoDateStr);
  
    // Get year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
  
    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    // Determine AM or PM
    const ampm = hours >= 12 ? 'pm' : 'am';
    
    // Convert 24-hour time to 12-hour time
    hours = hours % 12 || 12; // If hours is 0, make it 12 (for midnight)
  
    // Format final string as YYYY-MM-DD H:MMam/pm
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}${ampm}`;
  
    return formattedDate;
  }
  
  

  useEffect(()=>{
      // Add CSS to mobile menu links function
      if(currentUrl=="/"){
        setHomeClass("mob-active-class");
        setHowItWorksClass("");
        setAboutUsClass("");
        setFaqClass("");
        setAffiliateProgramClass("");
        
      }else if(currentUrl=="/howitworks"){
        setHomeClass("");
        setHowItWorksClass("mob-active-class");
        setAboutUsClass("");
        setFaqClass("");
        setAffiliateProgramClass("");
      }else if(currentUrl=="/aboutus"){
        setHomeClass("");
        setHowItWorksClass("");
        setAboutUsClass("/mob-active-class");
        setFaqClass("");
        setAffiliateProgramClass("");
      }else if(currentUrl=="faq"){
        setHomeClass("");
        setHowItWorksClass("");
        setAboutUsClass("");
        setFaqClass("mob-active-class");
        setAffiliateProgramClass("");
      }else if(currentUrl=="/affiliateprogram"){
        setHomeClass("");
        setHowItWorksClass("");
        setAboutUsClass("");
        setFaqClass("");
        setAffiliateProgramClass("mob-active-class");
      }
  },[])

  //Swap Tracker Warning
  const notify=async()=>{
    
    if(orderid.length==0){
    toast.warn('Please enter complete order id', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      autoDismiss: true,
      theme: "dark",
      transition: Bounce,
    })

    }else{
      try {
        const url=process.env.REACT_APP_URL+"/tx/status";
        const options={
          method:"POST",
          headers:{
            "Content-Type":"application/json"
          },
          body:JSON.stringify({id:orderid})
        }
      
      const response=await fetch(url, options);
      const data=await response.json();
      console.log(data)
      //This if statement will exicute if transaction exists in database
      if(data.message=="Transaction Found"){

        //This If statement will exicute if there is tx_hash exists
        if(data.tx.tx_hash!=null){
          console.log("tx hash exists")
          //This if statement will exicute if transaction is in process or failed
          if(data.tx.status=="exchanging" || data.tx.status=="sending" || data.tx.status=="confirming" || data.tx.status=="confirmed" || data.tx.status=="confirmation" || data.tx.status=="sending_confirmation" || data.tx.status=="hold" || data.tx.status=="hold*" || data.tx.status=="verifying" || data.tx.status=="failed" || data.tx.status=="error" || data.tx.status=="blacklist" || data.tx.status=="aml_check_failed" || data.tx.status=="refunded"){
            sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
            sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
            sessionStorage.setItem('txhash', data.tx.tx_hash);
            sessionStorage.setItem("depositaddress", data.tx.deposit_address);
            sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
            sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
            sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
            sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name);
            sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
            sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
            sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
            sessionStorage.setItem("local_get_amount", data.tx.get_amount);
            sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
            navigate("/progress")

        //This else if statement will exicute if transaction has successfully processed
        }else if(data.tx.status=="finished" || data.tx.status=="success"){
          sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
          sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
          sessionStorage.setItem('txhash', data.tx.tx_hash);
          sessionStorage.setItem("depositaddress", data.tx.deposit_address);
          sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
          sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
          sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
          sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name); 
          sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
          sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
          sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
          sessionStorage.setItem("local_get_amount", data.tx.get_amount);
          sessionStorage.setItem('completionTime', formatCustomDate(data.tx.completion_time));
          sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
          navigate("/success")

        
        
        
          // Incase status doesnot match above provided statuses then this else statement will process and show user transaction not found message
        }else{
          toast.error('Transaction Not Found!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
            });
        }

      // This Else statement will exicute if tx_hash field is empty
      }else{

          // Incase deposit has not been performed
          if(data.tx.status=="waiting" || data.tx.status=="wait" || data.tx.status=="new"){
                sessionStorage.setItem("ordertrackerid", data.tx.transaction_id);
                sessionStorage.setItem('txhashlink', data.tx.tx_hash_link);
                sessionStorage.setItem('txhash', data.tx.tx_hash);
                sessionStorage.setItem("depositaddress", data.tx.deposit_address);
                sessionStorage.setItem('recipientaddress', data.tx.recipient_address);
                sessionStorage.setItem("local_exchange_type", data.tx.transaction_type);
                sessionStorage.setItem("local_send_crypto_name", data.tx.sell_coin_name);
                sessionStorage.setItem("local_get_crypto_name", data.tx.get_coin_name); 
                sessionStorage.setItem("local_send_crypto_logo", data.tx.sell_coin_logo);
                sessionStorage.setItem("local_get_crypto_logo", data.tx.get_coin_logo);
                sessionStorage.setItem("local_send_amount", data.tx.sell_amount);
                sessionStorage.setItem("local_get_amount", data.tx.get_amount);
                sessionStorage.setItem("local_exchange_logo", data.tx.exchange_partner=="changenow"?changenow_black:(data.tx.exchange_partner=="changelly"?changelly_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="simpleswap"?simpleswap_black:(data.tx.exchange_partner=="changehero"?changehero_black:(data.tx.exchange_partner=="letsexchange"?letsexchange_black:(data.tx.exchange_partner=="stealthex"?stealthex_black:(data.tx.exchange_partner=="godex"?godex_black:exolix_black))))))));
                const currentDate = new Date();
                const timestamp = currentDate.getTime();
                const storedTimestamp = Number(data.tx.expiry_time);
                if(storedTimestamp>timestamp){
                  sessionStorage.setItem('depositstatus', 1);
                  sessionStorage.setItem('timerValue', 1);
                }else{
                  sessionStorage.setItem('depositstatus', 0);
                  sessionStorage.setItem('timerValue', 0);
                }
                navigate("/submit")
          }else{
            toast.error('Transaction Not Found!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
              });
          }
        }

      // This else statement will exicute if transaction doesnot exists in database
      }else{
        toast.error('Transaction Not Found!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
      }

      } catch (error) {
        toast.error('Network Error!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
      }
    }

  }

  return (

<nav className="navbar navbar-expand-lg navbar-light " style={{background:"transparent"}}>

  <div className='logo'>  
    <a className="navbar-brand" href="#"><img className='img-fluid' src={logo}/></a> 
  </div>

  <div className="collapse navbar-collapse " id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto navbar-links">
      <li className="nav-item">
        <NavLink className="nav-link" to="/">Home</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/howitworks">How It Works</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/affiliateprogram">Affiliate Program</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/faq">FAQ</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/aboutus">About Us</NavLink>
      </li>
      <li className="nav-item">
        <a className="nav-link order-tracker-anchor"  id="dropdownMenuLink" data-toggle="dropdown"  aria-expanded="false">Order Tracker</a>
        <div className="dropdown-menu " id="dropdown-tracker-menu" aria-labelledby="dropdownMenuLink" style={{borderRadius:"15px"}}>
        <input type="txt" className="form-control" id="swap-tracker-input" aria-describedby="emailHelp" placeholder="Enter Order Tracker ID" value={orderid} onChange={(e)=>{setOrderId(e.target.value.replace(/\s+/g, ''))}}></input>
        <button className="btn btn-primary swap-track-btn basement-font" onClick={notify}>Track Your Swap</button>
        </div>
      </li>
    </ul>

  </div>

<div className='flag-menu-btn'>
  <div className='flag dropdown show' style={{display:"flex", justifyContent:"end"}}>
  <a className="navbar-brand" href="#"  id="flag-drop-down" data-toggle="dropdown"  aria-expanded="false">
    <div style={{display:"inline-block", width:"45%"}}>
      <img className='img-fluid' src={lang}/>
    </div>
  <i className='flag-drop-icon fa-solid fa-chevron-down'></i> 
  </a>
  <div className="dropdown-menu " aria-labelledby="flag-drop-down" style={{borderRadius:"15px"}}>
  <div className='scrollbox-lang'>
  <a className="dropdown-item" href="#" style={{borderTopLeftRadius:"5px"}} onClick={()=>{setLanguage(language); localStorage.setItem("language", language)}}><div className='lang-img'><img className='img-fluid' src={language}/></div>EN-US</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(england); localStorage.setItem("language", england)}}><div className='lang-img'><img className='img-fluid' src={england}/></div>EN-GB</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(spain); localStorage.setItem("language", spain)}}><div className='lang-img'><img className='img-fluid' src={spain}/></div>Español</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(france); localStorage.setItem("language", france)}}><div className='lang-img'><img className='img-fluid' src={france}/></div>Française</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(germany); localStorage.setItem("language", germany)}}><div className='lang-img'><img className='img-fluid' src={germany}/></div>Deutch</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(italy); localStorage.setItem("language", italy)}}><div className='lang-img'><img className='img-fluid' src={italy}/></div>Italiana</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(portugal); localStorage.setItem("language", portugal)}}><div className='lang-img'><img className='img-fluid' src={portugal}/></div>Português</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(swedon); localStorage.setItem("language", swedon)}}><div className='lang-img'><img className='img-fluid' src={swedon}/></div>Svenska</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(china); localStorage.setItem("language", china)}}><div className='lang-img'><img className='img-fluid' src={china}/></div>中国人</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(japan); localStorage.setItem("language", japan)}}><div className='lang-img'><img className='img-fluid' src={japan}/></div>日本</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(pakistan); localStorage.setItem("language", pakistan)}}><div className='lang-img'><img className='img-fluid' src={pakistan}/></div>اردو</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(russia); localStorage.setItem("language", russia)}}><div className='lang-img'><img className='img-fluid' src={russia}/></div>Pусский</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(turkey); localStorage.setItem("language", turkey)}}><div className='lang-img'><img className='img-fluid' src={turkey}/></div>Türk</a>
  <a className="dropdown-item" href="#" onClick={()=>{setLanguage(saudia); localStorage.setItem("language", saudia)}}><div className='lang-img'><img className='img-fluid' src={saudia}/></div>عربى</a>
    
  </div>
  </div>
  </div>

    {/* Mobile Menu */}
    <div className='mobile-menu-div dropdown' style={{display:"none"}}>
    <a id="mobile-dropdown-menu" data-toggle="dropdown" aria-expanded="false"><img className='img-fluid' src={mobilemenu} style={{width:"40px"}}></img></a>
    
  {/* Dropdown Menu */}

  <div className="dropdown-menu " id="mob-drop-div" aria-labelledby="mobile-dropdown-menu" style={{borderRadius:"15px"}}>

  <Link className={"dropdown-item "+homeclass} to="/" style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} onClick={()=>{setHomeClass("mob-active-class"); setHowItWorksClass(""); setAboutUsClass(""); setFaqClass(""); setAffiliateProgramClass("");}}>Home</Link>
  <Link className={"dropdown-item "+howitworksclass} to="/howitworks" onClick={()=>{setHomeClass(""); setHowItWorksClass("mob-active-class"); setAboutUsClass(""); setFaqClass(""); setAffiliateProgramClass("");}}>How It Works</Link>
  <Link className={"dropdown-item "+affiliateprogramclass} to="/affiliateprogram" onClick={()=>{setHomeClass(""); setHowItWorksClass(""); setAboutUsClass(""); setFaqClass(""); setAffiliateProgramClass("mob-active-class");}}>Affiliate Program</Link>
  <Link className={"dropdown-item "+faqclass} to="/faq" onClick={()=>{setHomeClass(""); setHowItWorksClass(""); setAboutUsClass(""); setFaqClass("mob-active-class"); setAffiliateProgramClass("");}}>FAQ</Link>
  <Link className={"dropdown-item "+aboutusclass} to="/aboutus" onClick={()=>{setHomeClass(""); setHowItWorksClass(""); setAboutUsClass("mob-active-class"); setFaqClass(""); setAffiliateProgramClass("");}}>About Us</Link>
  
  </div>
    
</div>
</div>

</nav>

  );
};

export default Navbar;
